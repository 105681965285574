import { AWARD_IDS } from '../utils/constants';
import { getAPIRoot } from './fetch';

export const getWinnersCount = async ({ queryFilters, cb }) => {
  let apiRoot = await getAPIRoot();
  const { disciplines, categories, countries, time, find, profile, seed } =
    queryFilters;

  const url = `${apiRoot}${process.env.GATSBY_API_SEARCH_WINNER_COUNT}`;
  const options = {
    method: 'POST',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      award: 0,
      year: 2024,
      range: time[0],
      categories,
      disciplines,
      countries,
      isGoldAward: queryFilters?.awards?.includes(AWARD_IDS.IFDA_GOLD),
      isBestOfYear: queryFilters?.awards?.includes(AWARD_IDS.IFDSA_BEST),
      isSupportedByIF: queryFilters?.awards?.includes(
        AWARD_IDS.IF_SIP_SUPPORTED
      ),
      find,
      profileId: profile,
      seed,
    }),
  };

  const response = await fetch(url, options);
  const _winnersCount = await response.json();
  if (cb) {
    cb(_winnersCount);
  }
  return _winnersCount;
};

export const getProfiles = async ({ term, cb }) => {
  let apiRoot = await getAPIRoot();

  const url = `${apiRoot}${process.env.GATSBY_API_SEARCH_COMPANY}`;
  const options = {
    method: 'POST',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      term,
    }),
  };

  const response = await fetch(url, options);
  const _profiles = await response.json();
  cb(_profiles);
};

export const getJurors = async ({ term, cb }) => {
  let apiRoot = await getAPIRoot();

  const url = `${apiRoot}${process.env.GATSBY_API_SEARCH_JUROR}`;
  const options = {
    method: 'POST',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      term,
    }),
  };

  const response = await fetch(url, options);
  const _jurors = await response.json();
  cb(_jurors);
};

export const getStories = async ({ term = '', cb }) => {
  let apiRoot = await getAPIRoot();

  const url = `${apiRoot}${process.env.GATSBY_API_SEARCH_ARTICLE}`;
  const options = {
    method: 'POST',
    redirect: 'follow',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      term,
    }),
  };

  const response = await fetch(url, options);
  const _stories = await response.json();
  cb(_stories);
};
